@media screen and (max-width: 768px) {
    .image-products-services-left {
      margin-bottom: 30px !important; 
    }

    .image-products-services-middle {
      margin-top: 10px !important;
      margin-bottom: 30px !important;
      margin-left: 0 !important; 
    }

    .image-products-services-right {
      margin-bottom: 30px;
    }
}